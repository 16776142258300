import CroImgOne from '../assets/cro-1.png';
import CroImgTwo from '../assets/cro-2.png';
import CrpImgthree from '../assets/cro-3.jpg';

const croMainFeatures = [
	{
		id: 'cro-features-1',
		title: 'croFeaturesTitleOne',
		content: 'croFeaturesDescOne',
		img: CroImgOne,
	},
	{
		id: 'cro-features-2',
		title: 'croFeaturesTitleTwo',
		content: 'croFeaturesDescTwo',
		img: CroImgTwo,
		reversed: true,
	},
	{
		id: 'cro-features-3',
		title: 'croFeaturesTitleThree',
		content: 'croFeaturesDescThree',
		img: CrpImgthree,
	},
];

export default croMainFeatures;
