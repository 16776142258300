import React from 'react';
import Stage from '../../components/info-site/Stage';
import Layout from '../../layouts/en';
import Seo from '../../components/info-site/Seo';
import GetStarted from '../../components/info-site/GetStarted';

import StageContent from '../../components/info-site/StageContent';

import BrandPartnerships from '../../components/info-site/BrandPartnerships';
import croMainFeatures from '../../data/croMainFeatures';
import SideContentWithImg from '../../components/info-site/SideContentWithImg';
// import SimpleSideContent from '../../components/info-site/SimpleSideContent';
// import LogoWithTitle from '../../components/styleguide/LogoWithTitle';

const UpliftBasketCompletion = (props) => {
	return (
		<Layout location={props.location}>
			<Seo titleId="croTitle" descriptionId="croDescription" />
			<Stage title="croTitle" icon withoutMedia>
				<StageContent
					title="croSideTitle"
					content="croSideDescription"
					ctaLink="https://rtbmedia.hubspotpagebuilder.com/book-a-demo"
					ctaText="homepageStagePrimaryCta"
				/>
			</Stage>

			<BrandPartnerships title="croFeatures">
				{croMainFeatures.map(({ id, ...rest }) => (
					<SideContentWithImg lightParagraph key={id} {...rest} />
				))}
			</BrandPartnerships>

			<GetStarted />
		</Layout>
	);
};

export default UpliftBasketCompletion;
